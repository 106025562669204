import Vue from "vue";
import Vuex from "vuex";
import traces from "./modules/traces.store";
import flows from "./modules/flows.store";
import network from "./modules/network.store";
import layers from "./modules/layers.store";
import simulations from "./modules/simulations.store";
import capucine_analysis from "./modules/capucine_analysis.store";
import capucine_simulation from "./modules/capucine_simulation.store";
import capucine_results from "./modules/capucine_results.store";
import * as packageDesc from "../../package.json";
import { MAPCENTER, MAPZOOM, MAPPITCH } from "../global";
import { getInstance } from "@/api/index";
import { KiteAlert } from "@/models";
import { alertFromError } from "@/functions-tools";
import i18n from "@/plugins/lang";

Vue.use(Vuex);

const buildDate: Date = new Date(process.env.BUILD_DATE || "1970-01-01T00:00:00Z");
let version = packageDesc.version;
console.log("version", version, "buildDate", buildDate, window.location.hostname);
if (window.location.hostname !== "kite.tellae.fr") {
  // Increment patch version number
  const nums = version.split(".");
  nums[nums.length - 1] = (Number.parseInt(nums[nums.length - 1]) + 1).toString();
  version = nums.join(".");
  if (window.location.hostname === "localhost") {
    version += "-dev";
  } else if (window.location.hostname.match(/^pr\d+\./)) {
    version += "-preview";
  } else if (window.location.hostname === "beta.kite.tellae.fr") {
    version += "-beta";
  }
}

export default new Vuex.Store({
  modules: {
    traces,
    flows,
    network,
    layers,
    simulations,
    capucine_analysis,
    capucine_simulation,
    capucine_results
  },
  state: {
    version: `v${version}`,
    buildDate,
    language: "fr",
    apiAvailable: false,
    async: {
      generic: 0,
      overlay: 1,
      newProject: 0,
      updateProject: 0,
      updateUser: 0,
      gtfsRouteData: 0,
      gtfsDraw: 0,
      scenarioList: 0,
      downloadScenariofiles: 0,
      flows_database: 0,
      railway_stations: 0,
      communes: 0,
      dbLayers: 0,
      mapView: 0,
      draw_pt_line: 0,
      database_networks: 0,
      admin_entities: 0
    },
    mainDrawerOpen: true,
    mainDrawerTab: "welcome",
    overlayZoningSelection: true,
    alertDisplayed: false,
    alertState: {
      message: "",
      action: undefined
    },
    alertStack: [],
    mapActionState: {
      action: null,
      options: {},
      validationHandler: null,
      closeHandler: null
    },
    appPreset: undefined,
    appPresetObject: undefined,
    uxDisplay: {
      legends: true,
      animationCommands: false
    },
    mapInitialized: false,
    map: {
      zoom: MAPZOOM,
      center: MAPCENTER,
      pitch: MAPPITCH
    }
  },
  getters: {
    appPresetLoading: state => {
      return state.appPresetObject === undefined;
    }
  },
  actions: {
    async alert(context, payload: KiteAlert | String | Error) {
      // build alert object if String
      let alert;
      if (payload instanceof Error) {
        alert = alertFromError(payload);
      } else if (typeof payload == "string") {
        alert = {
          message: payload
        };
      } else {
        alert = payload;
      }
      // add alert to queue
      context.commit("ADD_ALERT_TO_STACK", alert);

      // if no alert is displayed, fetch next alert and display it
      if (!context.state.alertDisplayed) {
        context.commit("SHIFT_ALERT_STACK");
        context.commit("UPDATE_ALERT_DISPLAY", true);
      }
    },
    asyncStart(context, payload = { type: "generic" }) {
      if (typeof payload === "string") {
        payload = { type: payload };
      }
      context.commit("ASYNC_START", payload);
    },
    asyncEnd(context, payload = { type: "generic" }) {
      if (typeof payload === "string") {
        payload = { type: payload };
      }
      context.commit("ASYNC_END", payload);
    },
    async updateApiAvailability(context) {
      let whale = getInstance();
      try {
        let shark_version = await (await whale.controller.http("shark/version", { errorAlert: false }, true)).text();
        console.log(`Shark API version: ${shark_version}`);
        context.commit("SET_API_AVAILABLE", true);
      } catch {
        context.commit("SET_API_AVAILABLE", false);
      }
    },
    startMapAction(context, { action, options = {}, validationHandler, closeHandler }) {
      // test if map action dialog is already open
      if (context.state.mapActionState.action) {
        let message = i18n.t("map_actions.running");
        alert({ message, type: "warning" });
        return false;
      }

      // update map action state
      context.commit("UPDATE_MAP_ACTION_STATE", {
        action,
        options,
        validationHandler,
        closeHandler
      });

      return true;
    },
    finishMapAction(context, result) {
      // get validation handler
      let validationHandler = context.state.mapActionState.validationHandler;

      // call validation handler if provided
      if (validationHandler) {
        validationHandler(result);
      }
    },
    closeMapAction(context) {
      // get close handler
      let closeHandler = context.state.mapActionState.closeHandler;

      // close map action dialog
      context.commit("UPDATE_MAP_ACTION_STATE", {
        action: null,
        options: {},
        validationHandler: null,
        closeHandler: null
      });

      // call close handler if provided
      if (closeHandler) {
        closeHandler();
      }
    }
  },
  mutations: {
    ASYNC_START(state, payload) {
      state.async[payload.type] ??= 0;
      state.async[payload.type]++;
    },
    ASYNC_END(state, payload) {
      if (state.async[payload.type] > 0) {
        state.async[payload.type]--;
      }
    },
    SET_API_AVAILABLE(state, value) {
      state.apiAvailable = value;
    },
    ADD_ALERT_TO_STACK(state, alert: KiteAlert) {
      state.alertStack.push(alert);
    },
    SHIFT_ALERT_STACK(state) {
      state.alertState = state.alertStack.shift();
    },
    UPDATE_ALERT_DISPLAY(state, payload: Boolean) {
      state.alertDisplayed = payload;
    },
    UPDATE_UX_DISPLAY(state, payload) {
      state.uxDisplay[payload.ux] = payload.value;
    },
    UPDATE_MAIN_DRAWER(state, value) {
      state.mainDrawerOpen = value;
    },
    UPDATE_MAIN_DRAWER_TAB(state, value) {
      state.mainDrawerTab = value;
    },
    UPDATE_MAP_ACTION_STATE(state, payload) {
      state.mapActionState = payload;
    },
    SET_MAP_INITIALIZED(state, value) {
      state.mapInitialized = value;
    },
    SET_APP_PRESET(state, preset) {
      state.appPreset = preset;
    },
    SET_PRESET_OBJECT(state, object) {
      state.appPresetObject = object;
    }
  }
});
