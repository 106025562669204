import * as turf from "@turf/turf";
import { Units } from "@turf/turf";
import DataFrame from "dataframe-js";
import store from "./store";
import seedrandom from "seedrandom";
import Controller from "./store/controller";
import { getInstance } from "@/api/index";
import { AppPreset, KiteAlert } from "@/models";
import i18n from "./plugins/lang";
import { ValidationError } from "@/validation";
import { downloadData } from "@/io";
import { loadMapView } from "@/map_view";
import { union } from "polygon-clipping";

// convert hexadecimal color to array
// if hexa, convert to rgba
function hexToRGB(hex) {
  hex = hex.toUpperCase();
  let h = "0123456789ABCDEF";
  let r = h.indexOf(hex[1]) * 16 + h.indexOf(hex[2]);
  let g = h.indexOf(hex[3]) * 16 + h.indexOf(hex[4]);
  let b = h.indexOf(hex[5]) * 16 + h.indexOf(hex[6]);
  let colors = [r, g, b];
  if (hex.length == 9) {
    colors[3] = h.indexOf(hex[7]) * 16 + h.indexOf(hex[8]);
  }
  return colors;
}

function hexaToHex(hexa) {
  hexa = hexa.toUpperCase();
  let h = "0123456789ABCDEF";
  let hex = hexa.slice(0, 7);
  let a = (h.indexOf(hexa[7]) * 16 + h.indexOf(hexa[8])) / 255;
  return [hex, a];
}

function decToHex(value) {
  if (value > 255) {
    return "FF";
  } else if (value < 0) {
    return "00";
  } else {
    return value.toString(16).padStart(2, "0").toUpperCase();
  }
}
function rgbToHex(rgbColor) {
  return "#" + decToHex(rgbColor[0]) + decToHex(rgbColor[1]) + decToHex(rgbColor[2]);
}

/**
 * Split and format search string separated by a ;
 *
 * @param {string} search_string - Search key words
 *
 * @returns array
 */
function create_search_list(search_string) {
  let search_list = search_string.split(/,|;/);
  for (let i = 0; i < search_list.length; i++) {
    search_list[i] = search_list[i].toString().toLowerCase();
  }

  return search_list;
}

/**
 * Test if file extension is allowed
 *
 * @param {string} file_name - File name
 * @param {array} allowed_extensions - Allowed extensions
 *
 * @returns boolean
 */
function formatTester(file_name, allowed_extensions) {
  let test = false;
  if (file_name === null) {
    test = false;
  } else {
    let decoupe = file_name.split(".");
    if (decoupe.length < 2) {
      test = false;
    } else {
      let extension = decoupe[decoupe.length - 1];
      test = allowed_extensions.includes(extension);
    }
  }
  return test;
}

/**
 * Get user name from user email
 *
 * @param {*} email
 */
function get_user_name(email) {
  return email.replace("@", "_at_");
}

// geospatial distance between 2 points
function geodistanceturf(lat1, lon1, lat2, lon2, units: Units = "kilometers") {
  let from = turf.point([lon1, lat1]); // point as array lon, lat, see https://turfjs.org/docs/api/point
  let to = turf.point([lon2, lat2]);
  let options = { units: units };
  var distance = turf.distance(from, to, options);
  return distance;
}

// récupération de la visibilité d'un layer à partir de la liste des layers
function getLayerVisibility(layers, layer_name) {
  return layers.filter(x => x.name === layer_name)[0].visibility;
}

/**
 *
 * @param {*} layer
 * @param {*} agent
 * @param {*} checkFollowAgent
 * @param {*} selectedMovingAttribute
 * @param {*} hide
 */
function updateObjectLayer2(layer, agent, checkFollowAgent, selectedMovingAttribute, hide = false) {
  let value = "";
  if (layer.features !== undefined) {
    for (let i = 0; i < layer.features.length; i++) {
      if (checkFollowAgent) {
        if (layer.features[i].properties.agent_id === agent) {
          value = agent;
        } else {
          value = "";
        }
      } else {
        if (!hide) {
          value = layer.features[i].properties[selectedMovingAttribute];
        }
      }
      layer.features[i].properties.show_attribute = value;
    }
  }
}

/**
 * Convert second to hour format hh:mm
 *
 * @param {*} sec
 */
function sec2hourformat(sec) {
  let heure = Math.trunc(sec / 3600);
  let minute = Math.trunc((sec - heure * 3600) / 60);

  if (heure >= 24) heure = heure - 24;

  return ("0" + heure).slice(-2) + "h" + ("0" + minute).slice(-2);
}

//

/**
 * Get color for a type of object for deck.gl tripslayer
 *
 * @param {*} type
 */
function colorMovingObject(type) {
  let data = [255, 0, 0];
  switch (type) {
    case "t-user":
      data = [76, 133, 204];
      break;
    case "t-bike":
      data = [239, 123, 38];
      break;
    case "t-car":
      data = [255, 0, 0];
      break;
    case "t-kick-scooter":
      data = [239, 123, 38];
      break;
    case "t-scooter":
      data = [239, 123, 38];
      break;
    case "t-bus":
      data = [191, 203, 81];
      break;
    case "t-tram":
      data = [66, 177, 38];
      break;
    case "t-subway":
      data = [12, 168, 108];
      break;
    case "t-train":
      data = [0, 0, 0];
      break;
    default:
      data = [255, 0, 0];
  }
  return data;
}

/**
 * Get length for a type of object for deck.gl tripslayer
 *
 * @param {*} type
 */
function widthMovingObject(type) {
  let data = 30;
  switch (type) {
    case "t-user":
      data = 5;
      break;
    case "t-bike":
      data = 10;
      break;
    case "t-car":
      data = 5;
      break;
    case "t-kick-scooter":
      data = 10;
      break;
    case "t-scooter":
      data = 10;
      break;
    case "t-bus":
      data = 10;
      break;
    case "t-tram":
      data = 12;
      break;
    case "t-subway":
      data = 15;
      break;
    case "t-train":
      data = 17;
      break;
    default:
      data = 15;
  }
  return data;
}

// model code from a model name
function get_model_code(model) {
  // get model type
  let choosenModel = "";
  switch (model) {
    case "Transports publics":
      choosenModel = "TT_PT";
      break;
    case "Libre service avec station":
      choosenModel = "SB_VS";
      break;
    case "Libre service sans station":
      choosenModel = "FF_VS";
      break;
    default:
      choosenModel = "";
  }
  return choosenModel;
}

function getRandomSubarray(arr: any[], size: number, seed) {
  var rng = seedrandom(seed);
  var shuffled = arr.slice(0),
    i = arr.length,
    temp,
    index;
  while (i--) {
    index = Math.floor((i + 1) * rng());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(0, size);
}

function sortObjectByKeys(o) {
  return Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});
}

// update layer visibility
function majVisibility(checkStatus, layerName, map) {
  let layerVisibility = null;
  if (checkStatus) {
    layerVisibility = "visible";
  } else {
    layerVisibility = "none";
  }
  map.setLayoutProperty(layerName, "visibility", layerVisibility);
}

/**
 * Make the map zoom on the flowmap data
 * @param {*} map
 * @param {*} flowmap_data
 */
function zoomOnFlowmap(map, flowmap_data) {
  let features = [];
  for (let i = 0; i < flowmap_data.locations.length; i++) {
    features.push(turf.point([flowmap_data.locations[i].lon, flowmap_data.locations[i].lat]));
  }
  let fc = turf.featureCollection(features);
  zoomOnGeojson(map, fc);
}

/**
 * Make the map zoom on the geojson data
 * @param {*} map
 * @param {Object} geojson
 * @param {int} padding
 * @param {Array} offset
 */
function zoomOnGeojson(map, geojson, padding = 50, offset = [150, 0]) {
  // off-centre and padded (original value of padding was 0) zoom because of drawer
  // TODO : zoom depending on drawer open or not
  let bbox = turf.bbox(geojson);
  map.fitBounds(bbox, {
    padding,
    offset
  });
}

/**
 * Get animation start second from url parameters
 *
 * @param {Object} vm
 *
 * @returns given animation start in micro-second
 */
function get_start_microsecond_from_url(vm) {
  let startMicroSecond = 0;
  if (vm.$route.query.startSecond === undefined) {
    startMicroSecond = 0;
  } else {
    startMicroSecond = 1000 * parseFloat(vm.$route.query.startSecond);
  }
  return startMicroSecond;
}

/**
 *  Add some special rules for layer visibility on zoom level
 *  the function given to this callback will be called every time the map
 *  completes a zoom animation.
 *
 * @param {*} map    mapbox map
 * @param {*} layers list of layers containing zoom_rule property
 */
function apply_zoom_layer_rules(map, layers) {
  map.on("zoomend", function () {
    layers.forEach(element => {
      if (element.zoom_rule !== undefined) {
        // layers about paint properties
        if (element.zoom_rule.type === "text-opacity") {
          if (typeof map.getLayer(element.name) !== "undefined") {
            if (map.getZoom() < element.zoom_rule.zoom) {
              map.setPaintProperty(element.name, element.zoom_rule.type, element.zoom_rule.lower);
            } else {
              map.setPaintProperty(element.name, element.zoom_rule.type, element.zoom_rule.above);
            }
          }
        }

        // layer about layout properties
        if (element.zoom_rule.type === "visibility") {
          if (typeof map.getLayer(element.name) !== "undefined") {
            if (element.visibility === "visible") {
              if (map.getZoom() < element.zoom_rule.zoom) {
                map.setLayoutProperty(element.name, element.zoom_rule.type, element.zoom_rule.lower);
              } else {
                map.setLayoutProperty(element.name, element.zoom_rule.type, element.zoom_rule.above);
              }
            }
          }
        }
      }
    });
  });
}

function bbox_from_map(map) {
  let bbox = map.getBounds();
  let bbox_sw = bbox.getSouthWest();
  let bbox_ne = bbox.getNorthEast();
  return [
    Math.round(bbox_sw["lng"] * 1000) / 1000,
    Math.round(bbox_sw["lat"] * 1000) / 1000,
    Math.round(bbox_ne["lng"] * 1000) / 1000,
    Math.round(bbox_ne["lat"] * 1000) / 1000
  ];
}

/**
 * Read an array of a text file
 *
 * @param {Array} arr array of text file lines
 * @param {String} output_format data output format (object or dataframe)
 * @param {String} sep column separator
 *
 * @returns Object of data
 */
function read_csv_array(arr, output_format = "object", compulsory_attr = [], sep = ",") {
  // automatically get all columns
  var jsonObj = [];
  var headers = arr[0].split(sep);
  for (var i = 1; i < arr.length; i++) {
    var data = arr[i].split(sep);
    var obj = {};
    for (var j = 0; j < data.length; j++) {
      obj[headers[j].trim()] = data[j].trim();
    }
    if (data.length > 1) {
      jsonObj.push(obj);
    }
  }
  // convert numbers
  let all_keys = Object.keys(jsonObj[0]);
  for (let k = 0; k < all_keys.length; k++) {
    let key = all_keys[k];
    if (!isNaN(parseFloat(jsonObj[0][key]))) {
      for (let i = 0; i < jsonObj.length; i++) {
        if (isNaN(parseFloat(jsonObj[i][key]))) {
          console.log(jsonObj[i][key]);
        }
        jsonObj[i][key] = parseFloat(jsonObj[i][key]);
      }
    }
  }
  // add compulsory attributes
  console.log(headers);
  if (compulsory_attr.length > 0) {
    for (let attr = 0; attr < compulsory_attr.length; attr++) {
      if (!headers.includes(compulsory_attr[attr])) {
        for (let i = 0; i < jsonObj.length; i++) {
          jsonObj[i][compulsory_attr[attr]] = NaN;
        }
      }
    }
  }

  // convert to output format
  let out = jsonObj;
  if (output_format == "dataframe") {
    let df_arr = [];
    for (let i = 0; i < jsonObj.length; i++) {
      df_arr.push(jsonObj[i]);
    }

    out = new DataFrame(df_arr, Object.keys(jsonObj[0]));
  }

  return out;
}

function convertJsonToCsv(arrayOfJson) {
  // convert JSON to CSV
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(arrayOfJson[0]);
  let csv = arrayOfJson.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(","));
  csv.unshift(header.join(","));
  csv = csv.join("\r\n");
  return csv;
}

/**
 *  Change first character of string to upper case and remove '_' caracters
 *
 * @param {String} a
 * @returns string
 */
function formatText(a) {
  a = String(a);
  if (a.includes("_")) {
    let words = a.split("_");
    return (
      words[0].charAt(0).toUpperCase() +
      words[0].substr(1) +
      " " +
      words[1].charAt(0).toUpperCase() +
      words[1].substr(1)
    );
  } else {
    return a.charAt(0).toUpperCase() + a.substr(1);
  }
}

/**
 * Split the string & change first character to upper case for each word
 *
 * @param {String} str
 * @returns formatted string
 */
function upperCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // directly return the joined string
  return splitStr.join(" ");
}

/**
 * Create bins based on selected interval and group data by bins
 */
function groupByBin(x, y, interval) {
  interval = parseInt(interval);
  var bins = [];
  let max = Math.max(...x);
  let numOfBins = Math.trunc(max / interval);

  // setup bins
  for (let i = Math.min(...x); i < (numOfBins + 1) * interval; i += interval) {
    bins.push({
      minNum: i,
      maxNum: i + interval,
      count: 0
    });
  }
  // loop through data and add to bin's count
  for (let i = 0; i < x.length; i++) {
    var item = x[i];
    for (var j = 0; j < bins.length; j++) {
      var bin = bins[j];
      if (item >= bin.minNum && item < bin.maxNum) {
        bin.count += y[i];
        break;
      }
    }
  }
  // bins might be empty
  bins = bins.filter(b => b["count"] > 0);
  // return x and y in proper format
  x = bins.map(v => v.minNum);
  y = bins.map(v => v.count);
  return [x, y];
}

// /**
//  * Drop duplicated elements based on key
//  *
//  * @param {Array} arr array of objects
//  * @param {String} key selected key of objets
//  * @returns Array without duplicated key
//  */
// function getUniqueListBy(arr, key) {
//   return [...new Map(arr.map(item => [item[key], item])).values()];
// }

/**
 * Convert geometry from linestring to point in a feature collection
 *
 * @param {Object} rawData feature collection with linestrings
 * @returns Converted feature collection
 */
function convertLineStringtoPoints(rawData) {
  let geojson = {
    type: "FeatureCollection",
    features: []
  };
  for (let i = 0; i < rawData.features.length; i++) {
    let d = rawData.features[i];
    // add origin point
    geojson.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [d.geometry.coordinates[0][0], d.geometry.coordinates[0][1]]
      }
    });
    // add destination point
    geojson.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [d.geometry.coordinates[1][0], d.geometry.coordinates[1][1]]
      }
    });
  }
  return geojson;
}

/**
 * Transform the YYYYMMDD dates in a list of items.
 * value is the original date values and text is a human readable formated version of the date
 *
 * @param {Array} dates
 * @param {String} language
 * @param {*} format_func
 * @returns
 */
function dateListToItems(dates, language, format_func = dateFormat) {
  return dates.map(date => {
    let date_obj = parseDate(date);
    let formattedDate = format_func(date_obj, language);
    return { value: date, text: formattedDate };
  });
}

/**
 * Parses YYYYMMDD date into Date object
 * @param date
 */
function parseDate(date) {
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

/**
 * Convert the given Date object in a String
 * with the format 'Weekday DD Month YYYY'
 *
 * @param {Date} date
 * @param {String} language
 * @returns
 */
function dateFormat(date, language) {
  let options = { year: "numeric", month: "long", day: "numeric", weekday: "long" };
  return upperCase(date.toLocaleDateString(language, options));
}

/**
 * Get the url and layer name used to initialise vector tiles layers based on Martin.
 * @param source
 * @returns
 */
function getMartinSource(source) {
  return {
    url: Controller.getInstance().getUrl("/martin/" + source),
    "source-layer": source
  };
}

/**
 * Get the url for geojson layers given by Shark api.
 * @param source name of the source layer (see shark documentation for possible values)
 * @param bbox
 * @returns
 */
function getSharkSource(source, bbox?) {
  let url = `/shark/layers/geojson/${source}` + (bbox ? `?bbox=${bbox}` : "");
  return Controller.getInstance().getUrl(url);
}

/*
 * Create an separate section in the given schema with the advanced properties.
 *
 * @param schema
 * @return updated schema
 */
function separateSchemaAdvancedProps(schema) {
  let advanced_prop = {
    type: "object",
    title: "Advanced properties",
    "x-display": "expansion-panels",
    properties: {}
  };

  for (const prop in schema.properties) {
    let prop_schema = schema.properties[prop];
    if ("advanced" in prop_schema && prop_schema.advanced) {
      advanced_prop.properties[prop] = prop_schema;
      delete schema.properties[prop];
    }
  }

  if (Object.keys(advanced_prop.properties).length > 0) {
    schema.properties.advanced = advanced_prop;
  }

  return schema;
}

function formatTableDataWithHeaders(
  headers: Array<any>,
  data: Array<any>,
  format_func?: (a: any, value_key: string) => any,
  header_text_getter?: (header_text: string) => string
) {
  let formatted_data = [];
  for (let k = 0; k < data.length; k++) {
    let item = data[k];
    let new_item = {};
    for (let i = 0; i < headers.length; i++) {
      let header = headers[i];
      let value = null;
      if (format_func) {
        value = format_func(item, header.value);
      } else {
        value = item[header.value];
      }
      let header_text;
      if (header_text_getter) {
        header_text = header_text_getter(header.text);
      } else {
        header_text = header.text;
      }
      new_item[header_text] = value;
    }
    formatted_data.push(new_item);
  }
  return formatted_data;
}

/**
 * Convert simulation use case parameters to dict
 *
 * @param {Object} dict
 * @param {Object} parameters_name
 * @param {string} scenario_name
 * @returns
 */
function convertDictToRecords(
  dict: Object,
  parameters_name: Object,
  scenario_name: string,
  translate_boolean: Object = {}
) {
  let ans = [];
  // ordered by parameters_name dictionnary
  for (let key in parameters_name) {
    let value = dict[key];
    if (key == "commune_id" && value) {
      ans.push({ text: parameters_name[key], value: value["name"] });
    } else if (key == "railway_station" && value) {
      ans.push({ text: parameters_name[key], value: value["railway_station_name"] });
    } else if (key == "relocation") {
      ans.push({ text: parameters_name[key], value: translate_boolean[value] });
    } else if (["location", "city"].includes(key) && value) {
      ans.push({ text: parameters_name[key], value: value["text"] });
    } else if (key == "scenario_name") {
      ans.push({ text: parameters_name[key], value: scenario_name });
    } else {
      ans.push({ text: parameters_name[key], value: value });
    }
  }
  return ans;
}

/**
 * Truncate string and add "..." if too long
 * @param str
 * @param max_length
 * @returns truncated string
 */
function truncateString(str, max_length) {
  if (str.length > max_length) {
    return str.substring(0, max_length - 3) + "...";
  } else {
    return str;
  }
}

/**
 * Create a name that does not already exist in existingNames list
 * @param {string} baseName base name to
 * @param {Array<string>} existingNames list of already taken names
 * @returns final version of the name
 */
function generateNewNameIfExists(baseName: string, existingNames: Array<string>) {
  // test if the name already exists, otherwise add a suffix
  let final_name = baseName;
  let name_count = 0;
  while (existingNames.includes(final_name)) {
    name_count += 1;
    final_name = baseName + "(" + name_count + ")";
  }
  return final_name;
}

/**
 * Remove extension from filename
 * @param {String} filename
 * @returns filename without extension
 */
function removeExtensionFromFilename(filename: string) {
  return filename?.replace(/\.[^/.]+$/, "");
}

function getBinaryName(binary: any, with_extension: boolean = true) {
  let name = binary.metadata?.name || binary.name || binary.originalname;
  if (!with_extension) {
    name = removeExtensionFromFilename(name);
  }
  return name;
}

/**
 * Display an alert from the error message, with optional actions
 * depending on the type of error
 * @param error
 * @param alertMessage
 */
function alertFromError(error: Error): KiteAlert {
  if (error instanceof ValidationError) {
    // get error schema
    let schema = error.schema;

    // display alert with option to download schema
    return {
      message: error.message,
      action: {
        text: "Schema",
        handler: () => {
          let schema_str = JSON.stringify(schema);
          let filename = schema.title + ".json";
          downloadData(schema_str, filename);
        }
      },
      type: "error"
    };
  } else {
    return {
      message: error.message,
      type: "error"
    };
  }
}

async function loadAppPreset(preset: AppPreset) {
  let whale = getInstance();
  switch (preset.type) {
    // map with containing predefined layers must be loaded
    case "MapView":
      await store.dispatch("layers/getFullDatabaseLayersTable");
      await store.dispatch("network/getDatabaseGtfs");
      whale
        .getMapView(preset.uuid, { errorAlert: false })
        .then(map_view => {
          store.commit("SET_PRESET_OBJECT", map_view);
          loadMapView(map_view);
        })
        .catch(() => {
          let message = i18n.t("presets.map_view.load_error");
          alert({ message, type: "error" });
        });
      break;
    default:
      let message = i18n.t("presets.type_error");
      alert({ message, type: "error" });
      throw new Error("Unsupported preset type " + preset.type);
  }
}

/**
 * Sort a list according to another order-defining list.
 * @param list_to_sort list to sort
 * @param order_list ordered list of keys
 * @param key_accessor function returning the key used for sorting
 * @returns sorted list
 */
function sortFromList(list_to_sort, order_list, key_accessor?) {
  return list_to_sort.sort((a, b) => {
    if (key_accessor) {
      a = key_accessor(a);
      b = key_accessor(b);
    }
    let index_a = order_list.indexOf(a);
    let index_b = order_list.indexOf(b);
    if (index_a < index_b) {
      return 1;
    } else if (index_b < index_a) {
      return -1;
    } else {
      return 0;
    }
  });
}

function coordinatesToText({ lng, lat }) {
  return lng.toFixed(5).toString() + ", " + lat.toFixed(5).toString();
}

function mergeClippedFeatures(features_to_merge) {
  // check that features were given an id
  if (!features_to_merge[0].id) {
    throw new Error("Features are missing an id field, cannot merge them.");
  }

  // build dict of { feature_id: [corresponding features] }
  let feat_dict = {};
  features_to_merge.forEach(feature => {
    if (feature.id in feat_dict) {
      feat_dict[feature.id].push(feature);
    } else {
      feat_dict[feature.id] = [feature];
    }
  });

  // browse same id features
  let features;
  let coordinates;
  let merged_features = [];
  for (let id in feat_dict) {
    features = feat_dict[id];
    coordinates = features.map(i => i.geometry.coordinates);
    // merge geometries using the polygon-clipping library
    merged_features.push({
      id,
      type: features[0].type,
      geometry: {
        coordinates: union(coordinates[0], ...coordinates),
        type: "MultiPolygon"
      },
      properties: features[0].properties
    });
  }

  return merged_features;
}

function have_same_keys(obj1, obj2) {
  let keys1 = new Set(Object.keys(obj1));
  let keys2 = new Set(Object.keys(obj2));
  return keys1.size == keys2.size && [...keys1].every(x => keys2.has(x));
}

export {
  read_csv_array,
  hexToRGB,
  rgbToHex,
  hexaToHex,
  formatTester,
  get_user_name,
  geodistanceturf,
  majVisibility,
  updateObjectLayer2,
  sec2hourformat,
  colorMovingObject,
  widthMovingObject,
  getLayerVisibility,
  get_model_code,
  zoomOnFlowmap,
  zoomOnGeojson,
  get_start_microsecond_from_url,
  apply_zoom_layer_rules,
  bbox_from_map,
  formatText,
  groupByBin,
  sortObjectByKeys,
  upperCase,
  convertLineStringtoPoints,
  dateListToItems,
  parseDate,
  dateFormat,
  convertJsonToCsv,
  getRandomSubarray,
  getMartinSource,
  getSharkSource,
  separateSchemaAdvancedProps,
  convertDictToRecords,
  formatTableDataWithHeaders,
  truncateString,
  generateNewNameIfExists,
  removeExtensionFromFilename,
  getBinaryName,
  alertFromError,
  loadAppPreset,
  sortFromList,
  coordinatesToText,
  mergeClippedFeatures,
  have_same_keys,
  create_search_list
};
